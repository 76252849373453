import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import HeadingTextTwoColumns from "../components/HeadingTextTwoColumns/headingTextTwoColumns"
import TwoColumnImageBlock from "../components/TwoColumnImageBlock/twoColumnImageBlock"
import { H1, P } from "../styles/typography"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`

const CreditsWrapper = styled.div`
  padding: 0 0 50px;

  @media ${main.breakpoints.medium} {
    padding: 0 0 80px;
  }
`

const imageElements = [
  <StaticImage src="../images/galleri/Smedjan_10_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,
  <StaticImage src="../images/galleri/P1010665_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,

  <StaticImage src="../images/galleri/Smedjan_11_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,
  <StaticImage src="../images/galleri/Smedjan_13_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />, 

  <StaticImage src="../images/galleri/Smedjan_06_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,
  <StaticImage src="../images/galleri/Smedjan_09_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,

  <StaticImage src="../images/galleri/Smedjan_08_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,
  <StaticImage src="../images/galleri/Smedjan_12_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,

  <StaticImage src="../images/galleri/P1010649_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,
  <StaticImage src="../images/galleri/Smedjan_03_FotoHampusBerndtson_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,

  <StaticImage src="../images/galleri/P1010722_2.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,
  <StaticImage src="../images/galleri/P1010641_low.jpg" quality={95} formats={["AUTO", "WEBP"]} alt="" placeholder="blurred" />,
]

const Galleri = () => (
  <Layout>
    <Seo title="Galleri" />
    <HeadingWrapper>
      <H1 size="large">Galleri</H1>
    </HeadingWrapper>
    <StaticImage
      src="../images/galleri/Smedjan_04_FotoHampusBerndtson_low.jpg"
      quality={95}
      layout="fullWidth"
      formats={["AUTO", "WEBP"]}
      alt="Smedjans två hus"
      style={{ marginTop: `2.45rem` }}
    />
    <HeadingTextTwoColumns
      heading="Utforska det färdiga resultatet"
      text={[
        "Efter en varsam renovering och nytänkande arkitektur står nu Smedjan färdig – en harmonisk förening av Tredje Långgatans industriella arv och modern design. Här har rustika material mött samtida formspråk för att skapa en unik atmosfär, där historien känns närvarande i varje detalj. Utforska vårt bildgalleri och låt dig inspireras av hur Porthuset och Gårdshuset fått nytt liv, med respekt för det gamla men med blicken mot framtiden.",
      ]}
    />
    {imageElements.reduce((acc, image, index) => {
      const chunkSize = 2;
      const lastArray = acc[acc.length - 1];
      if (lastArray.length < chunkSize) {
        lastArray.push(image);
      } else {
        acc.push([image]);
      }
      return acc;
    }, [[]]).map((chunk) => (
      <TwoColumnImageBlock
        leftImageEl={chunk[0]}
        rightImageEl={chunk[1]}
      />
    ))}
    <CreditsWrapper>
        <P size="large">Foto: Hampus Berndtson / Aron Wändahl Högberg</P>
    </CreditsWrapper>
  </Layout>
)

export default Galleri
